<template>
  <div class="home">
    <!-- Main body started -->
    <div class="one">
      <div class="one_box">
        <div class="userinfo">
          <ul v-if="userDetail">
            <h2>
              <StaticImage
                :url="'Head/' + userDetail.avatarAddress + '.png'"
                @click="showAvatarModal"
              ></StaticImage>
            </h2>
            <h3>
              <span>{{ userDetail.userNick }}</span>
              <VipLevel :level="userDetail.levelName?.slice(3)" />
            </h3>
            <li>
              <router-link to="/inviteFriends">
                <b class="iconfont icon-shuju"></b>
                <span>{{ t("home.inviteFriends") }}</span>
              </router-link>
            </li>
          </ul>
        </div>
        <div class="usermoney" v-if="userDetail">
          <ul>
            <li>
              {{ t("profile.label.walletBalance") }}
              <span>{{ formatMoney(userDetail.amount) }}</span>
            </li>
            <li>
              <!-- <p>
                {{ t("home.commission") }}
                <span>{{ formatMoney(userDetail.commission) }}</span>
              </p> -->
              <p>
                {{ t("home.yesterdayEarnings") }}
                <span>{{ formatMoney(userDetail.yesterdayIncome) }}</span>
              </p>
              <p>
                {{ t("home.cumulativeIncome") }}
                <span>{{ formatMoney(userDetail.totalIncome) }}</span>
              </p>
              <p>
                {{ t("home.todayEarnings") }}
                <span>{{ formatMoney(userDetail.toDayIncome) }}</span>
              </p>
              <!-- <p>
                {{ t("team.label.samedaySubcCmmission") }}
                <span>{{ formatMoney(userDetail.subRebate) }}</span>
              </p> -->
            </li>
            
            <li>
              <router-link to="/recharge">{{ t("home.recharge") }}</router-link>
              <router-link to="/withdraw">{{ t("home.withdraw") }}</router-link>
            </li>
          </ul>
        </div>
      </div>
      <div class="news">
        <div class="news_box">
          <i class="iconfont icon-laba"></i>
          <marquee-x :message="(lunContent && lunContent.content) || ''" />
        </div>
      </div>
    </div>
    <!-- <div class="sys_ad">
      <p>
        <router-link to="/depository">
          <StaticImage v-if="locale === 'en'" url="site_21.png" alt="" />
          <StaticImage v-if="locale === 'pb'" url="site_21_pb.png" alt="" />
        </router-link>
      </p>
    </div> -->
    <!-- <div class="two downBox" @click="download">
      <div class="two_box">{{ t("profile.menu.download") }}<span class="downIcon"><i class="iconfont icon-xiazai"></i></span></div>
    </div> -->
    <div class="two">
      <div class="two_box">
        <div class="title">
          <i></i>
          {{ t("common.title.starPeople") }}
        </div>
        <div v-if="scorllList && userDetail" class="list h300">
          <div id="con1" class="con1" ref="con1" :class="{anim:animate==true}" @mouseenter="mEnter" @mouseleave="mLeave">
            <p v-for='(item, index) in scorllList' :key="index" @click="showImgDialog(item.img)">
              <span class="w20"><img :src="sysDataDictionaryWbObj.TXHQDZ?.dataContent + item.img " alt=""/></span>
              <span class="w20">{{item.userName}}</span>
              <span class="w40">{{item.content}}</span>
              <span class="w20 redtxt">{{formatMoney(item.amount)}}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="three">
      <div class="title">
        <i></i>
        {{ t("home.BeginnersGuide") }}
      </div>
      <ul>
        <li>
          <router-link to="/WhyUs">
            <StaticImage v-if="locale === 'en'" url="site_45.png" alt="" />
            <StaticImage v-if="locale === 'pb'" url="site_45_pb.png" alt="" />
          </router-link>
        </li>
        <li>
          <router-link to="/HowToEarn">
            <StaticImage v-if="locale === 'en'" url="site_46.png" alt="" />
            <StaticImage v-if="locale === 'pb'" url="site_46_pb.png" alt="" />
          </router-link>
        </li>
        <li>
          <router-link to="/HowToShare">
            <StaticImage v-if="locale === 'en'" url="site_47.png" alt="" />
            <StaticImage v-if="locale === 'pb'" url="site_47_pb.png" alt="" />
          </router-link>
        </li>
      </ul>
    </div> -->
    <AModal
      v-if="tanContent"
      v-model:visible="visible"
      centered
      :footer="null"
      :title="tanContent.name"
      @ok="handleOk"
      wrapClassName="Sys_Popup"
      width="85%"
      :zIndex="9999"
    >
      <div class="Sys_Popup_wrap">
        <div class="box text-pre-line">
          {{ (tanContent && tanContent.content) || "" }}
        </div>
      </div>
    </AModal>

    <AModal
      v-model:visible="isShowImg"
      centered
      
      :title="null"
      @ok="handleOk"
      wrapClassName="Sys_Popup"
      width="85%"
      :zIndex="9999"
    >
      <div class="Sys_Popup_wrap">
        <div class="box text-pre-line" style="text-align:center;">
          <img :src="sysDataDictionaryWbObj.TXHQDZ?.dataContent + showImgUrl "/>
        </div>
      </div>
    </AModal>

    <AModal
      centered
      :mask="true"
      :maskClosable="false"
      v-model:visible="avatarModal"
      @ok="changeAvatar"
      :okText="t('common.button.ok')"
      :cancelText="t('common.button.cancel')"
      :zIndex="9999"
      width="85%"
      wrapClassName="Sys_Popup"
      :title="t('home.ChooseYourAvatar')"
    >
      <div class="Sys_Popup_wrap">
        <a-radio-group v-model:value="chooseAvatar" name="radioGroup">
          <a-radio v-for="(item, index) in 33" :key="index" :value="'' + index">
            <StaticImage
              :style="{
                width: '30px',
                height: '30px',
                borderRadius: '30px',
                marginTop: '5px',
                marginBottom: '5px',
              }"
              :url="'Head/' + index + '.png'"
            ></StaticImage>
          </a-radio>
        </a-radio-group>
      </div>
    </AModal>
  </div>
  <div v-if="sysDataDictionaryWbObj?.Telegram?.dataOnOff" @click="clickKeFu" style="background: #fff;width: 70px;height: 70px;position: fixed;z-index: 999999;left: 80%;top: 69%;border-radius: 60px;">
    <!-- <a v-if="sysDataDictionaryWbObj?.Telegram?.dataOnOff" :href="sysDataDictionaryWbObj?.Telegram?.dataContent"> -->
      <img style="width: 35px;height:35px;top:71.5%;position: fixed;z-index: 999999;left: 85%;" src="../../src/assets/images/kefu.png">
    <!-- </a> -->
  </div>
  
</template>
<script>
import { ref, defineComponent, onMounted } from "vue";
import MarqueeX from "../components/MarqueeX";
// import { message } from "ant-design-vue";
import { formatMoney, formatPercentage } from "@/utils/formatter";
import { useI18n } from "@/lang";
import VipLevel from "@/components/VipLevel.vue";
import imageRobai from "@/Content/images/site_21.png";
import StaticImage from "@/components/StaticImage.vue";
import { useRouter } from "vue-router";
import { userDetail } from "@/hooks/userDetail";
import { lunContent, tanContent } from "@/hooks/announcement";
import { grabId } from "@/hooks/grabId";
import api from "@/api";
import { sysDataDictionaryWbObj } from "@/hooks/sysDataDictionaryWbObj";
export default defineComponent({
  name: "Home",
  components: { MarqueeX, VipLevel, StaticImage },
  setup() {
    const { t, locale } = useI18n();
    const handleOk = () => {
      isShowImg.value = false
    };
    const router = useRouter();
    let visible = ref(true);
    let avatarModal = ref(false);
    let chooseAvatar = ref("0");
    let animate = ref(true);
    let isShowImg = ref(false);
    let showImgUrl = ref("");
    
    // let vipPrivileges = inject("vipPrivileges");
    // let latestSiteNotice = inject("latestSiteNotice");
    // const unreadMessageCount = inject("unreadMessageCount");
    // let missionList = ref(null);
    let scorllList = ref(null);

    const download = () => {
      var u = navigator.userAgent;
      // var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
      var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isiOS) {
        if (sysDataDictionaryWbObj?.value?.APPXZLJ?.dataContent) {
          location.href = sysDataDictionaryWbObj?.value?.APPXZLJ?.dataContent;
        }
      } else {
        if (
          sysDataDictionaryWbObj?.value?.TXHQDZ?.dataContent &&
          sysDataDictionaryWbObj?.value?.AZAPKPZ?.dataContent
        ) {
          location.href =
            sysDataDictionaryWbObj?.value?.TXHQDZ?.dataContent +
            sysDataDictionaryWbObj?.value?.AZAPKPZ?.dataContent;
        }
      }
    };

    onMounted(async () => {
      let res = await api.webHomeRecords();
      if (res?.data?.success) {
        scorllList.value = res.data.data || [];
      }
    });

    function onClick(level, id, largestAmount) {
      // if (!userDetail.value.levelName) {
      //   return;
      // }
      // if (+userDetail.value.levelName?.slice(3) < level) {
      //   return;
      // }
      if(parseFloat(userDetail.value.amount) < parseFloat(largestAmount)){
        // message.error(t("error.server.NoEnoughBalance"));
        return
      }
      grabId.value = id;
      router.push({ path: `/grab/${level}` });
    }

    function mEnter(){

    }
    function mLeave(){

    }
    function showImgDialog(url){
      isShowImg.value = true
      showImgUrl.value =  url
    }

    async function changeAvatar() {
      let res = await api.updateAvatar(chooseAvatar.value);
      if (res && res.data && res.data.success) {
        avatarModal.value = false;
        api.getUserDetails();
      }
    }

    function clickKeFu(){
      // console.log(222)
      if(sysDataDictionaryWbObj.value?.Telegram?.dataContent){
        // console.log(111)
        window.open(sysDataDictionaryWbObj.value?.Telegram?.dataContent)
      }
    }
    function showAvatarModal() {
      avatarModal.value = true;
      chooseAvatar.value = userDetail.value.avatarAddress;
    }

    return {
      visible,
      handleOk,
      // unreadMessageCount,
      formatMoney,
      formatPercentage,
      // vipPrivileges,
      // latestSiteNotice,
      imageRobai,
      onClick,
      t,
      locale,
      userDetail,
      avatarModal,
      changeAvatar,
      showAvatarModal,
      chooseAvatar,
      lunContent,
      tanContent,
      // missionList,
      sysDataDictionaryWbObj,
      scorllList,
      mEnter,
      mLeave,
      animate,
      download,
      isShowImg,
      showImgUrl,
      showImgDialog,
      clickKeFu
    };
  },
});
</script>
<style lang="scss" scoped>
.disabledMission {
  background: #ddd;
  p,
  span,
  b {
    color: #666 !important;
  }
}

.h300{
  height: 360px;
  overflow:hidden;
}
.h300 img{
  width:40px;
  height: 40px;
}
.anim{
  // transition: all .8s;
  animation: myMove 12s linear infinite;
  animation-fill-mode: forwards;
}
 /*文字无缝滚动*/
@keyframes myMove {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-500px);
  }
}
.con1 p{
  padding: 5px 0;
}
.w30{
  width:30%;
  display: inline-block;
  text-align: right;
   white-space:nowrap; overflow:hidden; text-overflow:ellipsis;
}
.w20{
  width:20%;
  display: inline-block;
  text-align: left;
   white-space:nowrap; overflow:hidden; text-overflow:ellipsis;
}
.w10{
  width:10%;
  display: inline-block;
  text-align: left;
   white-space:nowrap; overflow:hidden; text-overflow:ellipsis;
}
.w40{
  width:40%;
  display: inline-block;
  text-align: left;
  white-space:nowrap; overflow:hidden; text-overflow:ellipsis;
}
.redtxt{
  color:#e23d6d;
}
.downBox{
  margin-right: 2%;
  margin-left: 2%;
  width:96%;
  border-radius: 10px;
}
.downBox .two_box{
  padding:15px 0 15px 10px;
}
.downBox .downIcon{
  display: inline-block;
  margin-right: 20px;
  float: right;
}
.downIcon i{
  font-size: 18px;
  color:#e23d6d
}
.text-pre-line img{
  padding-top:10px;
  width: 100%;
}

</style>