<template>
  <div class="outbox">
    <div class="inbox" ref="inbox">
      <div class="box" ref="messageBox" style="transform: translateX(1000px)">
        {{ message }}
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, onUnmounted } from 'vue';

export default defineComponent({
  name: 'MarqueeX',
  props: {
    message: String,
  },

  setup() {
    let inbox = ref(null);
    let messageBox = ref(null);
    let timer = null;
    let initOffset = 200;

    onMounted(() => {
      let offset = initOffset;

      timer = setInterval(() => {
        if (messageBox.value) {
          offset--;
          if (offset < -messageBox.value.clientWidth) {
            offset = initOffset;
          }
          messageBox.value.style = 'transform: translateX(' + offset + 'px)';
        }
      }, 16);
    });

    onUnmounted(() => {
      timer ? clearInterval(timer) : '';
    });

    return {
      messageBox,
      inbox,
    };
  },
});
</script>

<style lang="scss" scoped>
.outbox {
  position: relative;
  height: 30px;
  overflow: hidden;

  .inbox {
    position: absolute;
    font-size: 0;
    white-space: nowrap;

    .box {
      display: inline-block;
      height: 30px;
      font-size: 19px;
      line-height: 30px;
    }
  }
}
</style>
